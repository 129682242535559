<template>
	<div class="mt-4">
		<h3>{{ noticia.TITULO }}</h3>
		<v-chip label small>{{ parseDate(noticia.FECHA) }}</v-chip>
		<span> - </span>
		<span v-html="text.replace(/\n/g, '<br />')"></span>
		<v-btn @click.stop="more = !more" v-if="wrapped" class="d-block" small color="primary" text rounded>
			<v-icon left>{{ !more ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
			{{ $tc('noticias.mas_menos', Number(!more)) }}
		</v-btn>
	</div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { parseDate } from '@/utils/index'

const props = defineProps({
	noticia: Object
})

const max = 800

const more = ref(false)
const wrapped = computed(() => props.noticia.NOTICIA.length > max)
const text = computed(() => more.value ? props.noticia.NOTICIA : props.noticia.NOTICIA.slice(0, max))

watch(text, (v) => {
	console.log(v);
})

</script>